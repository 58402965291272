/* @tailwind base; */
@tailwind components;
@tailwind utilities;

ul,ol {
  padding-left: 1rem;
}

.markdown-text{
  white-space: pre-line;
}

/* .markdown-text ol, .markdown-text ul{
  line-height: 5px;
}  */

.bot .markdown-text code {
  background-color: rgba(110,118,129,0.4);
  color: inherit;
}

.markdown-text code {
  background-color: #9596ea;
  color: #fff;
  padding: 0.2em 0.4em;
  white-space: break-spaces;
  border-radius: 6px;
}

.table-responsive::-webkit-scrollbar{
  display: none;
}

.markdown-text h1,h2,h3,h4,h5,h6{
  margin-top: 0.5rem
}

.markdown-text > *:first-child{
  margin-top: 0;
}

.markdown-text > * {
  margin-bottom: 0;
}

.markdown-text > *:last-child{
  margin-bottom: 0;
}

table{
  margin: 10px 0;
}

th {
  font-size: 15px;
  line-height: 20px;
} 

td {
  font-size: 13px;
  line-height: 18px;
}

.markdown-text > table:first-child{
  margin-bottom: 0;
}

.rotate-90 {
  transform: rotate(90deg);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.codeStyle {
  margin: 20px 0 !important;
}

.codeStyle::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #912d21;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #912d2a;
}

.chat-typing-indicator span.dot {
  height: 6px;
  width: 6px;
  margin: 0 3px;
  float: left;
  /* background-color: #2C2D98; */
  display: block;
  border-radius: 50%;
  opacity: 0.2;
}

@media screen and (max-width: 850px){
  p, li, a{
    font-size: 13px;
    line-height: 18px;
  }

  .chat-typing-indicator span.dot {
    height: 4px;
    width: 4px;
  }
}

.chat-typing-indicator span.dot:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
          animation: 1s blink infinite 0.3333s;
}

.chat-typing-indicator span.dot:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
          animation: 1s blink infinite 0.6666s;
}

.chat-typing-indicator span.dot:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
          animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}
@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

.message-input:empty:before {
  content:attr(placeholder);
  color: gray;
}

.rotate180{
  transform: rotate(180deg);
}

.show-menu { 
  transform: translateY(0px);
}

.hide-menu { 
  transform: translateY(+100%);
}

.showMenu{
  transform: translateY(0);
}

.hideMenu{
  transform: translateY(100%);
}
.show-msg-menu { 
  transform: translateY(0px);
}

.hide-msg-menu { 
  transform: translateY(+100%);
}
.show-source-menu {
  transform: translateY(0px);
}
.hide-source-menu {
  transform: translateY(+100%);
}
.show-lang-menu {
  transform: translateX(0px);
}
.hide-lang-menu {
  transform: translateX(+100%);
}

.language-ct .language-list li {
  border-bottom: 1px solid #ECECEC;
}
.message-menu-ct .menu-item {
  border-bottom: 1px solid #ECECEC !important;
}
.message-menu-ct .menu-item:last-child {
  border-bottom: none !important;
}
.source-list-ct .source-item {
  border-bottom: 1px solid #ECECEC !important;
}
.source-list-ct .source-item:last-child {
  border-bottom:none !important;
}
.menu-ct .menu-item {
  border-bottom: 1px solid #ECECEC !important;
}
.menu-ct .menu-item:last-child {
  border-bottom: none !important;
}
.zoomIn {
  /* opacity: 0; */
  animation: zoomInAnimation 0.5s forwards;
}
.show-dialog {
  transform: translateY(0);
}

.hide-dialog {
  transform: translateY(-100%);
}

.rotating-animation {
  animation: 1.4s linear 0s infinite normal none running spinners-react-circular-fixed
}

@keyframes zoomInAnimation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeInAnimation 1s forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.max-3-lines{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  max-height: 54px;
}

.que-popup-ct-hide{
  display: none;
}
.que-popup-ct-show {
  display: block
}
.propmpt-suggestion-ct{
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

.send-message{
  top: 50%;
  transform: translateY(-50%);
}

.warning-message{
  border-top: 1px solid #ededed;
}